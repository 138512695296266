.tahoe-map {
    height: 100%;
    flex-grow: 2;
    position: relative;
}

.map-label {
    border: none;
    color: #191919b5;
    cursor: default;

    width: 80px;

    font-size: 10px;
    text-transform: uppercase;
    text-align: center;
    
    transform: translate(-50%, 0%);
}

.my-circle-marker {
    background-color: #0d5061ab;
    border-radius: 50%;
    width: 15px;
    height: 15px;

    cursor: pointer;
}

.my-circle-marker-active {
    background-color: #ddb307;
}

.marker-popup .leaflet-popup-tip {
    display: none;
}

.marker-popup .leaflet-popup-close-button {
    display: none;
}

.marker-popup .leaflet-popup-content {
    margin: 0px;
}

.marker-popup .leaflet-popup-content-wrapper {
    background: rgba(0,0,0,.5);
    color: #fff;
    border: 2px solid white;
    border-radius: 0px;
    padding: 0px;

    text-transform: uppercase;
    font-family: 'Source Sans Pro', sans-serif;
}

.marker-popup-offset-down .leaflet-popup-content-wrapper {
    transform: translate(0%, calc(100% + 40px));
}

.marker-popup-offset-right .leaflet-popup-content-wrapper {
    transform: translate(50%, 0px);
}

.marker-popup-offset-down-right .leaflet-popup-content-wrapper {
    transform: translate(50%, calc(100% + 40px));
}

.photo-marker-popup {
    padding: 13px;
}

.activity-marker-popup {
    border-bottom: 1px solid #fff;
    padding: 13px;
    margin: -1px;
}

.activity-marker-popup:last-child {
    border-bottom: none;
}

.activity-icon-container {
    margin-top: 5px;
    display: flex;
}

.activity-icon-container > * {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7481%) hue-rotate(220deg) brightness(96%) contrast(104%);
    margin-right: 5px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.activity-icon-marker-container {
    background: #fff;
    width: 24px;
    height: 24px;
    border-radius: 5px;
}

.activity-icon-marker {
    filter: brightness(0) saturate(100%) invert(21%) sepia(91%) saturate(433%) hue-rotate(143deg) brightness(100%) contrast(93%);
    width: 24px;
    height: 24px;
}

.color-marker {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #0d5061;
    width: fit-content;
    padding: 2px 10px;
    border-radius: 10px;
    
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
}

.material-icon-marker {
    display: flex;
    justify-content: center;
    align-items: center;
    
    background-color: #0d5061;
    width: fit-content;
    padding: 5px;
    border-radius: 50%;
    color: #fff;
}

.error-icon {
    background-color: #610d0d;
    padding: 5px;
    border-radius: 50%;
}

.loading-icon {
    background-color: #e0cf4b;
    padding: 5px;
    border-radius: 50%;
    animation: spin 1.5s infinite;
}

.loading-icon-active {
    padding: 3px;
    border: 3px solid #ddb307;
}
.error-icon-active {
    padding: 3px;
    border: 3px solid #ddb307;
}
.color-icon-active {
    border: 3px solid #ddb307;
    padding: 1px 9px;
}
.material-icon-marker-active {
    border: 3px solid #ddb307;
    padding: 3px;
}

@keyframes spin {
    0% {
        rotate: 0deg;
    }
    100% {
        rotate: 360deg;
    }
}

.acknowledgements {
    position: absolute;
    left: 15px;
    bottom: 15px;
    z-index: 1000;

    cursor: pointer;
    text-transform: uppercase;
    color: #0f5160;
    font-weight: 400;
    font-size: 15.6px;
}

.acknowledgements-popup {
    background-color: #0d5061;
    border: 1px solid white;
    max-width: 200px;
    padding: 24px;
    display: none;
    
    color: white;
    cursor: auto;
    text-transform: none;
    font-size: 10px;
    line-height: 20px;
    font-weight: 300;
}

.acknowledgements img {
    margin: 10px 0px;
}

.acknowledgements-title {
    cursor: pointer;
}

.acknowledgements-header {
    text-transform: uppercase;
    text-align: center;
    font-size: 15px;
    margin-bottom: 13px;
}

.acknowledgements-close {
    font-weight: 400;
    font-size: 13px;
    cursor: pointer;
}

.survey-marker-popup {
    padding: 13px;
    box-sizing: border-box;
    
    max-height: 500px;
    overflow-y: auto;
    width: 400px;

    font-size: 20px;
    text-transform: none;
}

.survey-response {
    margin-top: 20px;
}

.survey-question {
    font-style: italic;
    font-weight: 400;
}

.survey-images {
    width: 100%;
}

.survey-images img {
    margin-top: 5px;
    width: 100%;
    height: 100%;
}

.survey-map-marker .leaflet-popup-tip {
    display: none;
}

.survey-map-marker .leaflet-popup-close-button {
    display: none;
}

.survey-map-marker .leaflet-popup-content {
    margin: 0px;
}

.survey-map-marker .leaflet-popup-content-wrapper {
    background: rgba(0,0,0,.5);
    color: #fff;
    border: 2px solid white;
    border-radius: 0px;
    padding: 0px;
    width: 400px;

    text-transform: uppercase;
    font-family: 'Source Sans Pro', sans-serif;
}
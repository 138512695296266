.lake-conditions-chart-container {
    flex: 1 1 200px;

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.lake-conditions-chart-container > svg {
    display: block;

    width: 700px;
    height: 350px;
    margin-left: 100px;

    font-size: 15px;
}

.chart-title {
    position: absolute;
    top: 15px;
    left: 15px;
    text-transform: uppercase;
    font-size: 20px;
}
/***************************************************************
                        module Selector
****************************************************************/
.module-selector {
    width: 100%;
    
    display: flex;
    justify-content: space-evenly;

    position: relative;
    margin: 30px 0px;
}

.module-selector::before {
    content: " ";
    height: 1px;
    background-color: var(--primary-font);
 
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50%;
    transform: translate(0%, -50%);
}


/***************************************************************
                        module Buttons
****************************************************************/

.module-button {
    position: relative;
    
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    
    width: 40px;
    height: 40px;
    padding: 20px;
    
    border: 1px solid var(--primary-font);
    background-color: var(--ui-background);
    border-radius: 20px;
    transform: rotate(45deg);

    font-size: 15px;
    text-transform: uppercase;
    color: var(--primary-font);
}

.module-button > img {
    position: absolute;
    width: 100%;
    height: 100%;
    border: inherit;
    border-radius: inherit;
    display: none;
}

.module-button > span {
    position: absolute;
    transform: rotate(-45deg);
}

.module-button-active {
    transform: none;
}

.module-button-active > span {
    transform: none;
}

.module-button-active > img {
    display: block;
}
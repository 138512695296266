.photos-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.photos-img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50%;
}

.photos-location {
    font-size: 20.4px;
    text-transform: uppercase;
    
    position: absolute;
    top: 60px;
    left: 20px;
}

.photos-credit {
    font-size: 12px;
    text-transform: uppercase;

    position: absolute;
    right: 20px;
    bottom: 10px;
}

.photos-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    
    height: 100%;
    font-size: 22px;
}

.live-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 200px;
}

.live-container > iframe {
    width: 100%;
    height: 100%;
}
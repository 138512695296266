.module-bottom-tabs {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin-bottom: 10px;
    border-top: 1px solid var(--page-background);
}

.module-bottom-tab {
    text-transform: uppercase;
    text-align: center;
    font-size: 15px;
    
    flex-grow: 2;
    margin: 0px 15px;

    border: 1px solid var(--page-background);
    border-top: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;
}

.module-bottom-tab-active {
    background-color: var(--page-background);
}
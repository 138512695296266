.module-container {
    width: 100%;
    
    display: flex;
    flex-direction: column;
    flex: 1 1 200px;

    position: relative;
}

.module-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}

.fill-parent {
    flex-grow: 2;
}

.citizen-science-msg {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 2;

    font-size: 30px;
}
